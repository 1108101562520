import { prepareUserBody } from "../../utils/api_params";
import { prepareBodyWithoutId, prepareLoginBody } from "../../utils/api_params";
export const authApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      signout: build.mutation({
        query: () => ({
          url: "v1/sessions",
          method: "DELETE",
        }),
      }),
      addUser: build.mutation({
        query: (attributes) => ({
          url: "/v1/users",
          method: "POST",
          body: JSON.stringify(prepareUserBody(attributes)),
          timeout: 60000,
        }),
      }),
      deleteAccount: build.mutation({
        query: () => ({
          url: "v1/me",
          method: "DELETE",
        }),
      }),
      getMe: build.query({
        query: () => {
          return "v1/me";
        },
      }),
      updatePreference: build.mutation({
        query: (attributes) => ({
          url: `v1/me/user_preferences`,
          method: "PATCH",
          body: JSON.stringify(
            prepareBodyWithoutId(attributes, "user_preferences")
          ),
        }),
      }),
      confirmRegistration: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v1/user/confirm_registration",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
      login: build.mutation({
        query: (body) => ({
          method: "POST",
          url: "v1/sessions",
          body: JSON.stringify(prepareLoginBody(body)),
        }),
      }),
      resetPassword: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v1/user/reset_password",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
      changePassword: build.mutation({
        query: ({ userId, attributes }) => ({
          url: `v1/user/${userId}/change_password`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
      confirmResetPassword: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v1/user/reset_password",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "user")),
        }),
      }),
      getValidation: build.query({
        query: () => {
          return `v1/validate_password`;
        },
      }),
    }),
  });
