import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Alert,
  Grid,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { store } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slices/authSlice";

import { selectLogo } from "../../utils/logos/logoSelector";

const AuthPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logged = useSelector((state) => state?.authSlice?.logged);
  const currentToken = useSelector((state) => state?.authSlice?.currentToken);
  const code = useSelector((state) => state?.authSlice?.registerCode);
  const preference = useSelector(
    (state) => state.userPreferenceSlice?.preference
  );
  const [firstRender, setFirstRender] = useState(true);
  const [login] = store.useLoginMutation();
  const userInfo = useSelector((state) => state?.userPreferenceSlice?.userInfo);
  const [getMe] = store.useLazyGetMeQuery();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ username: "", password: "" });
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const bearer = localStorage.getItem("user_token");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validate = () => {
    let tempErrors = { username: "", password: "" };
    let isValid = true;

    if (!username) {
      tempErrors.username = "Username is required";
      isValid = false;
    }

    if (!password) {
      tempErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  useEffect(() => {
    if (code) {
      navigate("/registration");
    }
  }, [code]);

  useEffect(() => {
    setFirstRender(false);
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginError("");

    if (validate()) {
      login({
        email: username,
        password: password,
      })
        .unwrap()
        .then((data) => {
          debugger;
          dispatch(actions.signin(data?.data?.token));
        })
        ?.catch((error) => {
          console.error("Error occurred:", error);
          setLoginError("Invalid username or password");
        });
    }
  };

  useEffect(() => {
    if (currentToken && !userInfo) {
      getMe();
    }
  }, [currentToken, userInfo]);

  useEffect(() => {
    if (bearer && currentToken) {
      navigate("/");
    }
  }, [bearer]);

  return (
    <>
      {!firstRender && (
        <Container component="main" maxWidth="xs">
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              marginTop: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 3,
              backgroundColor: "#f5f5f5",
            }}
          >
            <Grid container justifyContent="center" sx={{ mb: 4 }}>
              <Box component="div">{selectLogo()}</Box>
            </Grid>
            <Typography
              component="h1"
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              {t("welcome_back")}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ mb: 3 }}
            >
              {t("login_text")}
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
              onSubmit={handleSubmit}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label={`${t("email")}`}
                name="username"
                autoFocus
                value={username}
                onChange={handleChange}
                error={Boolean(errors.username)}
                helperText={errors.username}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("password")}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid
                container
                justifyContent="space-between"
                sx={{ mt: 1, mb: 2 }}
              >
                <Link
                  href="/registration"
                  variant="body2"
                  underline="always"
                  sx={{
                    cursor: "pointer",
                    color: "#1976d2",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {t("registration")}
                </Link>
                <Link
                  href="/reset_password"
                  variant="body2"
                  underline="always"
                  sx={{
                    cursor: "pointer",
                    color: "#1976d2",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {t("forgot_password")}
                </Link>
              </Grid>

              {loginError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {loginError}
                </Alert>
              )}
              {/*  <a href="met3r://ResetPassword?code=F7YUVD"> per artiom </a> */}
              <Button
                type="submit"
                fullWidth
                variant={window.BUTTON_TYPE}
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("login")}
              </Button>
            </Box>
          </Paper>
        </Container>
      )}
    </>
  );
};

export default AuthPage;
