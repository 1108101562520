import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/material";
import Header from "./Header";
import Divider from "@mui/material/Divider";
import { useNavigate, useLocation } from "react-router-dom";
import ApartamentIndex from "./ApartamentIndex";
import Typography from "@mui/material/Typography";
import theme from "./theme";
const escludedPats = [
  "/login",
  "/reset_password",
  "/registration",
  "/confirm-reset",
  "/confirm-registration",
];

const CardWrapper = (props) => {
  const location = useLocation();

  return (
    <>
      <Card
        sx={{
          minHeight: 900,
          height: "fit-content",
          background: "#f5fcfb",
          m: 1,
        }}
      >
        <CardContent
          sx={{
            maxHeight: !escludedPats.includes(location?.pathname) && 900,
            height: escludedPats.includes(location?.pathname) && 900,
            overflowY: "auto",
            mt: 2,
          }}
        >
          {props.children}
        </CardContent>
      </Card>
    </>
  );
};
export default CardWrapper;
